import { createSlice } from "@reduxjs/toolkit";
import {
  getCustomersWithServiceTickets,
getServiceTicketsByStatusList,
getServiceTicketsForTheLastXDays
} from "./actions";

const initialState = {
  loading: false,
  serviceTicketsByStatusList: {
    loading: false,
    data: [],
    error: null,
  },
  customersWithServiceTickets: {
    loading: false,
    data: [],
    error: null,
  },
  allSvcTickets:{
    loading: false,
    data: [],
    error: null,
  },
  error: null,
  success: false,
};
const authSlice = createSlice({
  name: "serviceTickets",
  initialState,
  reducers: {},
  extraReducers: {
    [getServiceTicketsByStatusList.pending]: (state) => {
      state.loading = true;
      state.serviceTicketsByStatusList.loading = true;
      state.serviceTicketsByStatusList.error = null;
    },
    [getServiceTicketsByStatusList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketsByStatusList.loading = false;
      state.serviceTicketsByStatusList.data = payload;
    },
    [getServiceTicketsByStatusList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceTicketsByStatusList.loading = false;
      state.serviceTicketsByStatusList.error = payload;
    },
    [getCustomersWithServiceTickets.pending]: (state) => {
      state.loading = true;
      state.customersWithServiceTickets.loading = true;
      state.customersWithServiceTickets.error = null;
    },
    [getCustomersWithServiceTickets.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.customersWithServiceTickets.loading = false;
      state.customersWithServiceTickets.data = payload;
    },
    [getCustomersWithServiceTickets.rejected]: (state, { payload }) => {
      state.loading = false;
      state.customersWithServiceTickets.loading = false;
      state.customersWithServiceTickets.error = payload;
    },
    [getServiceTicketsForTheLastXDays.pending]: (state) => {
      state.loading = true;
      state.allSvcTickets.loading = true;
      state.allSvcTickets.error = null;
    },
    [getServiceTicketsForTheLastXDays.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.allSvcTickets.loading = false;
      state.allSvcTickets.data = payload;
    },
    [getServiceTicketsForTheLastXDays.rejected]: (state, { payload }) => {
      state.loading = false;
      state.allSvcTickets.loading = false;
      state.allSvcTickets.error = payload;
    },
  },
});

export default authSlice.reducer;