import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";

export const createMachineModel = createAsyncThunk("machines/createMachineModel", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/createMachineModel", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateMachineModel = createAsyncThunk("machines/updateMachineModel", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post("/serviceapi/updateMachineModel", payload);
    return response;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
