import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { readAsyncStorageValues } from "../../features/common/actions";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, Grid, Button, Stack } from "@mui/material";
import moment from "moment";
import { getServiceTicketsForCustomer } from "../../features/customers/actions";
import { exportToCsv } from "../../utils/utilFunctions";
import { getCustomersWithServiceTickets } from "../../features/servicetickets/actions";

const AllServiceTickets = () => {
  const dispatch = useDispatch();
  const { serviceTicketsForCustomer } = useSelector((state) => state.customers);
  const { customersWithServiceTickets } = useSelector((state) => state.serviceTickets);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getCustomersWithServiceTickets());
  }, [dispatch]);

  useEffect(() => {
    const fetchCustomerServiceTickets = async () => {
      try {
        await dispatch(getServiceTicketsForCustomer(selectedCustomer.customerId));
      } catch (error) {
        console.error("Error fetching service tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedCustomer) {
      setLoading(true);
      fetchCustomerServiceTickets();
    }
  }, [selectedCustomer]);

  const onCustomerChange = (event, newValue) => setSelectedCustomer(newValue);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const columns = [
    { field: "serviceTicketId", headerName: "ID", width: 70 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.2,
      sortComparator: dateComparator,
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      flex: 0.2,
      sortComparator: dateComparator,
    },
    { field: "Service Engineer", headerName: "Service Engineer", width: 250 },
    { field: "ticketCategory", headerName: "Category", width: 250 },
    { field: "status", headerName: "Status", width: 150 },
  ];
  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Service Ticket Id, Date Created, Date Closed, Service Engineer,Ticket Category, Status"];
    const keys = ["serviceTicketId", "dateCreated", "closedDate", "Service Engineer", "ticketCategory", "status"];
    exportToCsv(headers, keys, serviceTicketsForCustomer.data, "Service_Tickets");
  };

  return (
    <div>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mx={4} my={2}>
        <Autocomplete
          disablePortal
          id="customerdropdown"
          options={customersWithServiceTickets.data.slice().sort((a, b) => a.customerName.localeCompare(b.customerName))}
          onChange={onCustomerChange}
          value={selectedCustomer}
          getOptionLabel={(option) => option.customerName}
          renderInput={(params) => (
            <TextField {...params} label="Customer Name" sx={{ width: 300, marginTop: "10px", marginLeft: "3px" }} />
          )}
        />
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
          onClick={downloadCsv}
          disabled={serviceTicketsForCustomer.data.length ? false : true}
        >
          Export to CSV
        </Button>
      </Stack>

      <Box sx={{ height: 700, px: 4, mt: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        {loading || serviceTicketsForCustomer.loading ? (
          <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
        ) : (
          <DataGrid
            rows={selectedCustomer ? serviceTicketsForCustomer.data : []}
            getRowId={(row) => row.serviceTicketId}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            localeText={{ noRowsLabel: "No tickets." }}
          />
        )}
      </Box>
    </div>
  );
};

export default AllServiceTickets;
