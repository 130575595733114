import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, Button, Stack, InputAdornment, IconButton } from "@mui/material";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { exportToCsv } from "../../utils/utilFunctions";
import { getServiceTicketsForTheLastXDays } from "../../features/servicetickets/actions";

const AllSvcTicketForLastXDays = () => {
  const dispatch = useDispatch();
  const { serviceTicketsForCustomer } = useSelector((state) => state.customers);
  const { allSvcTickets } = useSelector((state) => state.serviceTickets);
  const [days, setDays] = useState("30");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchCustomerServiceTickets = async () => {
      try {
        await dispatch(getServiceTicketsForTheLastXDays(days));
      } catch (error) {
        console.error("Error fetching service tickets:", error);
      } finally {
        setLoading(false);
      }
    };

    if (days) {
      setLoading(true);
      fetchCustomerServiceTickets();
    }
  }, [days]);

  const onDaysChange = (event, newValue) => setDays(newValue);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const columns = [
    { field: "serviceTicketId", headerName: "ID", width: 70 },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.1,
      sortComparator: dateComparator,
      valueGetter: (params) => {
        const dateCreated = params.row?.dateCreated;
        if (dateCreated) {
          return moment(dateCreated).format("DD MMM YYYY");
        }
      },
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      flex: 0.1,
      valueGetter: (params) => {
        const closedDate = params.row?.closedDate;
        if (closedDate) {
          return moment(closedDate).format("DD MMM YYYY");
        }
      },
      sortComparator: dateComparator,
    },
    { field: "customerName", headerName: "Customer Name", width: 250, flex: 0.2 },
    { field: "ticketCategory", headerName: "Category", width: 250, flex: 0.2 },
    { field: "status", headerName: "Status", width: 150, flex: 0.2 },
  ];
  const downloadCsv = (e) => {
    e.preventDefault();

    const formattedData = allSvcTickets.data.map((ticket) => {
      return {
        serviceTicketId: ticket.serviceTicketId,
        dateCreated: ticket.dateCreated ? moment(ticket.dateCreated).format("DD MM YYYY") : "",
        closedDate: ticket.closedDate ? moment(ticket.closedDate).format("DD MM YYYY") : "",
        customerName: ticket.customerName,
        ticketCategory: ticket.ticketCategory,
        status: ticket.status,
      };
    });

    const headers = ["Service Ticket Id", "Date Created", "Date Closed", "Customer Name", "Ticket Category", "Status"];
    const keys = ["serviceTicketId", "dateCreated", "closedDate", "customerName", "ticketCategory", "status"];

    exportToCsv(headers, keys, formattedData, `All_Service_Tickets_for_last_${days} days`);
  };

  return (
    <Stack justifyContent="center" sx={{ mx: 2, my: 5 }}>
      <Stack direction={"row"} sx={{ mx: 3 }} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems="center">
          <Autocomplete
            disablePortal
            id="days"
            options={["30", "60", "90", "180"]}
            onChange={onDaysChange}
            value={days}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="Days" />}
          />
          <TextField
            style={{ marginLeft: 20, width: 300 }}
            label="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {searchTerm ? (
                    <IconButton edge="end" onClick={handleClearSearch} size="small">
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Button
          variant="contained"
          sx={{ textTransform: "capitalize", height: 50, ml: 2 }}
          onClick={downloadCsv}
          disabled={allSvcTickets.data.length ? false : true}
        >
          Export to CSV
        </Button>
      </Stack>

      <Box style={{ height: 700, width: "100%", paddingLeft: "2%", paddingTop: 3, marginTop: 8, paddingRight: "1%", overflow: "auto" }}>
        {loading || allSvcTickets.loading ? (
          <CircularProgress color="secondary" sx={{ justifyContent: "center", alignItems: "center" }} />
        ) : (
          <DataGrid
            rows={
              days
                ? allSvcTickets.data.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchTerm.toLowerCase()))
                : []
            }
            getRowId={(row) => row.serviceTicketId}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            disableColumnMenu
            localeText={{ noRowsLabel: "No tickets." }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default AllSvcTicketForLastXDays;
