import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Stack,
  Autocomplete,
  TextField,
  Button,
  Dialog,
  Alert,
  Typography,
  Box,
  Input,
  TextareaAutosize,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { readAsyncStorageValues } from "../../features/common/actions";
import { createLead, readAllCustomersByState, deleteLead } from "../../features/customers/actions";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Zoom from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import SearchIcon from "@mui/icons-material/Search";
import { exportToCsv } from "../../utils/utilFunctions";

const Customers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [indianStates, setIndianStates] = useState([]);
  const [formModal, setFormModal] = useState(false);

  const [selectedIndianState, setSelectedIndianState] = useState({ label: "Maharashtra (MH)", value: "MH" });
  const [addStateDropDown, setAddStatesDropDown] = useState({ label: "", value: "" });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCustName, setDeleteCustName] = useState("");
  const [deleteCustId, setDeleteCustId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const { register: addFormRegister, handleSubmit: addHandleSubmit, reset: addFormReset } = useForm();

  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const { loading: customersLoading, allCustomersByState } = useSelector((state) => state.customers);
  const { loading: commonLoading, indianStateDropdownOptions } = useSelector((state) => state.common);
  useEffect(() => {
    const fetch = async () => {
      dispatch(readAsyncStorageValues());
      await dispatch(readAllCustomersByState({ indianStateCode: selectedIndianState.value }));
    };

    fetch();
    setLoading(false);
  }, [dispatch, selectedIndianState]);

  useEffect(() => {
    if (!commonLoading) setIndianStates(indianStateDropdownOptions);
  }, [commonLoading, customersLoading, indianStateDropdownOptions]);

  const onIndianStateChange = (event, newValue) => {
    if (newValue) {
      setSelectedIndianState(newValue);
      dispatch(readAllCustomersByState({ indianStateCode: newValue.value }));
    } else {
      setSelectedIndianState([]);
    }
  };
  const onIndianStateChange2 = (event, newValue) => {
    if (newValue) {
      setAddStatesDropDown(newValue);
      setValidationErrors((prevState) => ({ ...prevState, state: null }));
    } else {
      setValidationErrors((prevState) => ({ ...prevState, state: "Please select a state" }));
      setAddStatesDropDown({ label: "", value: "" });
    }
  };

  const CustomNoRowsOverlay = () => (
    <Box sx={{ height: 6 }}>
      <Typography style={{ marginLeft: 10 }}>No items.</Typography>
    </Box>
  );

  const inputChangeHandler = (event) => {
    const mobileNumberRegex = /^[0-9+().\-\s]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const yearRegex = /^[1-9]\d{3}$/;
    let id = event.target.id;
    let value = event.target.value;
    if (id == "name") {
      if (!value) {
        setValidationErrors((prevState) => ({ ...prevState, name: "Name is required" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, name: null }));
      }
    } else if (id === "personName") {
      if (!value) {
        setValidationErrors((prevState) => ({ ...prevState, personName: "Person name is required" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, personName: null }));
      }
    } else if (id === "number") {
      if (value && !mobileNumberRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, number: "Please enter a valid phone number" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, number: null }));
      }
    } else if (id === "email") {
      if (value && !emailRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, email: "Please enter a valid email" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, email: null }));
      }
    } else if (id === "personNumber") {
      if (value && !mobileNumberRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, personNumber: "Please enter a valid phone number" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, personNumber: null }));
      }
    } else if (id === "personEmail") {
      if (value && !emailRegex.test(value)) {
        setValidationErrors((prevState) => ({ ...prevState, personEmail: "Please enter a valid email" }));
      } else {
        setValidationErrors((prevState) => ({ ...prevState, personEmail: null }));
      }
    }
  };

  const deleteIconHandle = (data) => {
    setDeleteModal(true);
    setDeleteCustName(data.row.name);
    setDeleteCustId(data.row.id);
  };
  const deleteCustomer = async () => {
    let collection = [];
    collection.push(deleteCustId);
    let data = {};
    data.customerIds = collection;
    await dispatch(deleteLead(data));
    toast.success(`${deleteCustName} Customer is deleted`,{autoClose:1000});
    dispatch(readAllCustomersByState({ indianStateCode: selectedIndianState.value }));
    setDeleteModal(false);
  };

  const renderDetailsButton = (params) => {
    return (
      <>
        <Button
          // variant="contained"
          startIcon={<ZoomInIcon style={{ fontSize: 23 }} />}
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            navigate("/customer-contact/" + params.row.id);
          }}
        ></Button>
        <IconButton
          color="secondary"
          onClick={() => {
            deleteIconHandle(params);
          }}
        >
          <DeleteIcon style={{ color: "grey" }} />
        </IconButton>
      </>
    );
  };

  const handleClose = () => {
    setFormModal(false);
  };
  const handleCloseDelete = () => {
    setDeleteModal(false);
  };

  const submitForm = async (data) => {
    let collection = {};
    if (addStateDropDown.label && addStateDropDown.value && !validationErrors.length) {
      collection.state = addStateDropDown.label;
      collection.telephoneNumber = data.customerNumber;
      collection.emailAddress = data.customerEmail;
      collection.name = data.name;
      collection.billingAddress = data.billingAddress;
      collection.shippingAddress = data.shippingAddress;
      collection.gstNumber = data.gstNumber;
      collection.contacts = [{}];
      collection.contacts[0].mobileNumber = data.personNumber;
      collection.contacts[0].name = data.personName;
      collection.contacts[0].email = data.personEmail;
      await dispatch(createLead(collection));
      setValidationErrors({});
      setSelectedIndianState(addStateDropDown);
      toast.success(`Customer Added`,{autoClose:1000});
      dispatch(readAllCustomersByState({ indianStateCode: addStateDropDown.value }));
      setFormModal(false);
      addFormReset();
    } else {
      toast.warning("Please enter all the required field", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const handleAddClick = (data) => {
    setFormModal(true);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const columns = [
    // { field: "id", headerName: "ID", width: 100 },
    { field: "name", headerName: "Customer Name", width: 350, flex: 0.2 },
    { field: "emailAddress", headerName: "Email Address", width: 200, flex: 0.2 },
    { field: "telephoneNumber", headerName: "Phone Number", width: 100, flex: 0.2 },
    // { field: "location", headerName: "Location", width: 150, flex: 0.2 },
    { field: "billingAddress", headerName: "Billing Address", width: 150, flex: 0.2 },
    { field: "shippingAddress", headerName: "Shipping Address", width: 100, flex: 0.2 },
    { field: "gstNumber", headerName: "GST Number", width: 100, flex: 0.2 },
    {
      field: "col6",
      headerName: "",
      width: 150,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: false,
      sortable: false,
      filterable: false,
    },
  ];

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Id,Customer Name, State, Email Address, Telephone Number, Billing Address, Shipping Address, GST Number"];
    const keys = ["id", "name", "state", "emailAddress", "telephoneNumber", "billingAddress", "shippingAddress", "gstNumber"];
    const formattedData = allCustomersByState.customers.map((item) => {
      let obj = {};
      for (const key of keys) obj = { ...obj, [key]: item[key] ? item[key].toString().replace(/(\r\n|\n|\r)/gm, "") : "" };
      return obj;
    });
    exportToCsv(headers, keys, formattedData, selectedIndianState.label + "_Customers");
  };

  return loading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
      <CircularProgress color="secondary" />
    </div>
  ) : (
    <div>
      <Stack sx={{ marginY: 5 }}>
        <div style={{ display: "flex", flexDirection: "row", marginLeft: 20, justifyContent: "space-between", flexWrap: "wrap" }}>
          <Stack direction={"row"} gap={2}>
            <Autocomplete
              disablePortal
              id="SelectStateCutomerEdit"
              options={indianStates}
              onChange={onIndianStateChange}
              value={selectedIndianState}
              sx={{ minWidth: 400, ml: 1, height: 50 }}
              renderInput={(params) => <TextField {...params} label="State" />}
            />
            <TextField
              style={{ minWidth: 400, boxShadow: "0px 3px 15px rgba(0,0,0,0.1)" }}
              label="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchTerm ? (
                      <IconButton edge="end" onClick={handleClearSearch} size="small">
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack direction={"row"} gap={2}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              color="primary"
              onClick={handleAddClick}
              sx={{ textTransform: "capitalize", mr: 1 }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              sx={{ textTransform: "capitalize", mr: 1 }}
              onClick={downloadCsv}
              disabled={allCustomersByState.customers.length ? false : true}
            >
              Export to CSV
            </Button>
          </Stack>
        </div>
        <Dialog open={formModal} alignSelf="center" onClose={handleClose} sx={{ width: 750, marginLeft: "30%" }}>
          <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>
            {"Add Customer"}
            <IconButton
              onClick={() => {
                setFormModal(false);
                setAddStatesDropDown({ label: "", value: "" });
                addFormReset();
                setValidationErrors({});
              }}
              style={{ position: "absolute", top: 0, right: 0 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Box
            component="form"
            onSubmit={addHandleSubmit(submitForm)}
            noValidate
            sx={{ mt: 1, padding: 10, paddingTop: 0, alignSelf: "center" }}
          >
            <Autocomplete
              disablePortal
              id="SelectStateByCustomer"
              options={indianStates}
              onChange={onIndianStateChange2}
              name="state"
              value={addStateDropDown}
              sx={{ width: 500, height: 50, marginBottom: 1.5 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State*"
                  error={Boolean(validationErrors.state)}
                  helperText={validationErrors.state ? validationErrors.state : ""}
                />
              )}
            />
            <br></br>
            Customer Details
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Customer Name"
              name="name"
              autoFocus
              {...addFormRegister("name")}
              error={Boolean(validationErrors.name)}
              helperText={validationErrors.name ? validationErrors.name : ""}
              onChange={inputChangeHandler}
            />
            <TextField
              margin="normal"
              fullWidth
              id="number"
              label="Customer Number"
              name="number"
              autoFocus
              {...addFormRegister("customerNumber")}
              error={Boolean(validationErrors.number)}
              helperText={validationErrors.number ? validationErrors.number : ""}
              onChange={inputChangeHandler}
            />
            <TextField
              margin="normal"
              // required
              fullWidth
              id="email"
              label="Customer Email"
              name="email"
              autoComplete="email"
              autoFocus
              {...addFormRegister("customerEmail")}
              error={Boolean(validationErrors.email)}
              helperText={validationErrors.email ? validationErrors.email : ""}
              onChange={inputChangeHandler}
            />
            <TextField
              margin="normal"
              multiline
              // required
              fullWidth
              id="billingAddress"
              label="Billing Address"
              name="billingAddress"
              autoFocus
              {...addFormRegister("billingAddress")}
              onChange={inputChangeHandler}
            />
            <TextField
              margin="normal"
              multiline
              // required
              fullWidth
              id="shippingAddress"
              label="Shipping Address"
              name="shippingAddress"
              autoFocus
              {...addFormRegister("shippingAddress")}
              onChange={inputChangeHandler}
            />
            <TextField
              margin="normal"
              multiline
              // required
              fullWidth
              id="gstNumber"
              label="GST Number"
              name="gstNumber"
              autoFocus
              {...addFormRegister("gstNumber")}
              onChange={inputChangeHandler}
            />
            Person Details
            <TextField
              margin="normal"
              required
              fullWidth
              id="personName"
              label="Person Name"
              name="personName"
              autoFocus
              {...addFormRegister("personName")}
              error={Boolean(validationErrors.personName)}
              helperText={validationErrors.personName ? validationErrors.personName : ""}
              onChange={inputChangeHandler}
            />
            <TextField
              margin="normal"
              // required
              fullWidth
              id="personNumber"
              label="Phone Number"
              name="personNumber"
              autoFocus
              {...addFormRegister("personNumber")}
              error={Boolean(validationErrors.personNumber)}
              helperText={validationErrors.personNumber ? validationErrors.personNumber : ""}
              onChange={inputChangeHandler}
            />
            <TextField
              margin="normal"
              // required
              fullWidth
              id="personEmail"
              label="Person Email"
              name="personEmail"
              autoComplete="email"
              autoFocus
              {...addFormRegister("personEmail")}
              error={Boolean(validationErrors.personEmail)}
              helperText={validationErrors.personEmail ? validationErrors.personEmail : ""}
              onChange={inputChangeHandler}
            />
            <Button type="submit" disabled={customersLoading} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {customersLoading ? "Submiting..." : "Submit"}
            </Button>
          </Box>
        </Dialog>
        <Dialog open={deleteModal} alignSelf="center" onClose={handleCloseDelete}>
          <Alert
            open={deleteModal}
            onClose={handleCloseDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <div id="alert-dialog-title">Confirm Action</div>
            <div id="alert-dialog-description">Do you want to delete this customer {deleteCustName} ?</div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleCloseDelete}>No</Button>
              <Button onClick={deleteCustomer}>Yes</Button>
            </div>
          </Alert>
        </Dialog>
        {!customersLoading ? (
          <div style={{ height: 650, overflow: "auto", width: "100%", paddingLeft: "2%", paddingTop: 3, marginTop: 8 }}>
            <DataGrid
              rows={allCustomersByState.customers.filter(
                (item) =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  item.emailAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  item.telephoneNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  item.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  item.billingAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  item.shippingAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  item.gstNumber?.toLowerCase().includes(searchTerm.toLowerCase())
              )}
              columns={columns}
              pageSize={5}
              style={{ boxShadow: "0px 3px 15px rgba(0,0,0,0.2)" }}
              rowsPerPageOptions={[5]}
              disableColumnMenu
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
                noResultsOverlay: CustomNoRowsOverlay,
              }}
            />
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <CircularProgress color="secondary" />
          </div>
        )}
      </Stack>
      <ToastContainer />
    </div>
  );
};
export default Customers;
