import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";

export const readAsyncStorageValues = createAsyncThunk("common/readAsyncStorageValues", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`/salesapi/readAsyncStorageValues`);
    console.log("eddfewfe", data);
    const machineModels = data?.machineModels;
    const indianStateDropdownOptions = Object.entries(data?.allTypes?.STATES).map(([key, value]) => ({
      label: value,
      value: key,
    }));

    const indianStateDropdownOptionsCodeOnly = Object.entries(data?.allTypes?.STATES).map(([key, value]) => ({
      label: key,
      value: key,
    }));

    return { asyncStorageValues: data, indianStateDropdownOptions, indianStateDropdownOptionsCodeOnly, machineModels };
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
