import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart } from "recharts";
import { CircularProgress, Typography, Box, Paper, Autocomplete, TextField, Stack } from "@mui/material";
import { getServiceHoursByCustomerByYear } from "../../features/dashboard/actions";
import { readCustomersByState } from "../../features/customers/actions";

const ServiceHoursBy4YearsByStateForCustomerBar = () => {
  const dispatch = useDispatch()
  const [indianStates, setIndianStates] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedIndianState, setSelectedIndianState] = useState({ label: "Maharashtra (MH)", value: "MH" });
  const [selectedCustomer, setSelectedCustomer] = useState({ label: "Almats Branding Solutions Pvt.Ltd.Navi Mumbai. 35338", value: "170" });
  const { serviceHoursByCustomerByYear } = useSelector((state) => state.dashboard);
  const { loading: commonLoading, indianStateDropdownOptions } = useSelector((state) => state.common);
  const { loading: customersLoading, customersByState } = useSelector((state) => state.customers);

  useEffect(() => {
    const fetch = async () => {
      dispatch(readCustomersByState({ indianStateCode: selectedIndianState.value }));
      dispatch(getServiceHoursByCustomerByYear({ customerId: selectedCustomer.value }));
    };

    fetch();
  }, [dispatch, selectedIndianState, selectedCustomer]);

  useEffect(() => {
    if (!commonLoading) setIndianStates(indianStateDropdownOptions);
    if (!customersLoading) setCustomerOptions(customersByState.dropdownOptions);
  }, [commonLoading, customersLoading, indianStateDropdownOptions, customersByState.dropdownOptions]);

  const onIndianStateChange = (event, newValue) => {
    setSelectedIndianState(newValue);
    setSelectedCustomer({
      label: "",
    });
    dispatch(readCustomersByState({ indianStateCode: newValue.value }));
  };
  const onCustomerChange = (event, newValue) => {
    setSelectedCustomer(newValue);
    dispatch(getServiceHoursByCustomerByYear({ customerId: newValue.value }));
  };

  return serviceHoursByCustomerByYear.loading && commonLoading && customersLoading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Box>
      <Paper elevation={8} sx={{ padding: 1 }}>
        <Stack alignItems="center">
          <Typography variant="body1" align="center" sx={{ mb: 2, mt: 2 }}>
            Service Hours Last 5 Years By State For Customer
          </Typography>
          <Autocomplete
            disablePortal
            id="SelectStateServiceHoursBy4YearsByStateForCustomerBar"
            options={indianStates}
            onChange={onIndianStateChange}
            value={selectedIndianState}
            sx={{ width: 350, ml: 2 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />
          <br />
          <Autocomplete
            disablePortal
            id="SelectCustomerServiceHoursBy4YearsByStateForCustomerBar"
            options={customerOptions}
            onChange={onCustomerChange}
            value={selectedCustomer}
            sx={{ width: 350, ml: 2 }}
            renderInput={(params) => <TextField {...params} label="Customer" />}
          />
        </Stack>
        <ComposedChart
          width={380}
          height={280}
          data={serviceHoursByCustomerByYear.data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <Legend content={() => null} />
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="hours_spent" barSize={30} fill="#bd7ebe" name=" " />
        </ComposedChart>
      </Paper>
    </Box>
  );
};

export default ServiceHoursBy4YearsByStateForCustomerBar;
