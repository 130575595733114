import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart } from "recharts";
import { CircularProgress, Typography, Box, Paper, Autocomplete, TextField, Stack } from "@mui/material";
import { getServiceHoursForState4Years } from "../../features/dashboard/actions";

const ServiceHoursBy4YearsByStateBar = () => {
  const dispatch = useDispatch();
  const [indianStates, setIndianStates] = useState([]);
  const [selectedIndianState, setSelectedIndianState] = useState({
    label: "Maharashtra (MH)",
    value: "MH",
  });
  const { serviceHoursForState4Years } = useSelector((state) => state.dashboard);
  const { loading: commonLoading, indianStateDropdownOptions } = useSelector((state) => state.common);

  useEffect(() => {
    if (!commonLoading) setIndianStates(indianStateDropdownOptions);
  }, [commonLoading, indianStateDropdownOptions]);

  useEffect(() => {
    const fetch = async () => {
      dispatch(getServiceHoursForState4Years({ state: selectedIndianState.value }));
    };
    fetch();
  }, [dispatch, selectedIndianState?.value]);

  const onStateChange = (event, newValue) => {
    setSelectedIndianState(newValue);
    dispatch(getServiceHoursForState4Years({ state: newValue.value }));
  };

  return serviceHoursForState4Years.loading && commonLoading ? (
    <CircularProgress color="secondary" />
  ) : (
    <Box>
      <Paper elevation={8} sx={{ padding: 1 }}>
        <Stack alignItems="center">
          <Typography variant="body1" align="center" sx={{ mb: 2, mt: 2 }}>
            Service Hours Last 5 Years By State
          </Typography>
          <Autocomplete
            disablePortal
            id="SelectStateServiceHoursBy4YearsByStateBar"
            options={indianStates}
            onChange={onStateChange}
            value={selectedIndianState}
            sx={{ width: 300, ml: 2 }}
            renderInput={(params) => <TextField {...params} label="State" />}
          />
        </Stack>
        <ComposedChart
          width={350}
          height={360}
          data={serviceHoursForState4Years.data}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <Legend content={() => null} />
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="service_hours" barSize={30} fill="#7eb0d5" label={{ position: "top" }} name=" " />
        </ComposedChart>
      </Paper>
    </Box>
  );
};

export default ServiceHoursBy4YearsByStateBar;
