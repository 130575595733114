import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../app/api";

export const getServiceTicketsForTheLastXDays = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (days, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/serviceapi/getServiceTicketsForTheLastXDays?days=${days}`, {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceTicketsByStatusList = createAsyncThunk(
  "serviceTickets/getServiceTicketsByStatusList",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.post("/serviceapi/getServiceTicketsByStatusList", {
        statusList: payload.statusList,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCustomersWithServiceTickets = createAsyncThunk(
  "customers/getCustomersWithServiceTickets",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getCustomersWithServiceTickets`);

      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// const defaults = modified.map((object) => {
//   return {
//     2020: object["2020"] ? object["2020"] : 0,
//     2021: object["2020"] ? object["2020"] : 0,
//     2022: object["2020"] ? object["2020"] : 0,
//     2023: object["2020"] ? object["2020"] : 0,
//     indianState: object.indianState,
//     indianStateCode: object.indianStateCode,
//   };
// });
