import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../app/api";

export const sendInstallationReport = createAsyncThunk("dashboard/sendInstallationReport", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post(`/serviceapi/getInstallationReport?days=` + payload);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const sendWarrantyCallsDetails = createAsyncThunk("dashboard/sendWarrantyCallsDetails", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post(`/serviceapi/getWarrantyCallsDetails?days=` + payload);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const sendWeeklyServiceReports = createAsyncThunk("dashboard/sendWeeklyServiceReports", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.post(`/serviceapi/getWeeklyServiceReport`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getServiceHoursByYear4Years = createAsyncThunk(
  "dashboard/getServiceHoursByYear4Years",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getServiceHoursByYear4Years`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceHoursByMonths = createAsyncThunk("dashboard/getServiceHoursByMonths", async (payload, { rejectWithValue }) => {
  try {
    const { data } = await api.get(`/serviceapi/getServiceHoursForMonths?months=12`);
    const expanded = data.map((obj) => {
      const date = new Date(obj.month); // 2009-11-10
      const month = date.toLocaleString("default", { month: "short" });
      return { ...obj, monthName: month };
    });

    return expanded;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getServiceHoursForCustomerByMachine4Years = createAsyncThunk(
  "dashboard/getServiceHoursForCustomerByMachine4Years",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getServiceHoursForCustomerByMachine4Years?customerId=326`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceHoursByCustomerByYear = createAsyncThunk(
  "dashboard/getTotalServiceHoursForCustomerInStateByYear",
  async ({ customerId }, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getServiceHoursByCustomerByYear?customerId=${customerId}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceHoursForState4Years = createAsyncThunk(
  "dashboard/getServiceHoursForState4Years",
  async ({ state }, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getServiceHoursForState4Years?state=${state}`);
      const sorted = data.sort(function (a, b) {
        return a.year - b.year;
      });
      const filtered = sorted.filter((item) => item.year);

      return filtered;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getServiceHoursAllStatesLast4Years = createAsyncThunk(
  "dashboard/getServiceHoursAllStatesLast4Years",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await api.get(`/serviceapi/getServiceHoursAllStatesLast4Years`);

      const modified = Object.entries(data).map(([key, value]) => {
        const indianStateCode = key.split(/[()]+/).filter(function (e) {
          return e;
        })[1];
        return Object.assign(
          {
            indianState: key,
            indianStateCode,
          },
          ...value
        );
      });



      return modified;
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
